'use strict';

class Media {
    static prefersReducedMotion() {
        return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    }
}

class Scroll {
    smoothScrollHorizontal(element, left) {
        return this.smoothScroll(element, left, true);
    }

    smoothScrollVertical(element, top) {
        return this.smoothScroll(element, top, false);
    }

    smoothScroll(element, topOrLeft, horizontal) {
        if (this.supportsSmoothScroll()) {
            return element.scrollTo({
                [horizontal ? 'left' : 'top']: topOrLeft,
                behavior:                      'smooth',
            });
        } else {
            return this.smoothScrollPolyfill(element, horizontal ? 'scrollLeft' : 'scrollTop', topOrLeft);
        }
    }

    supportsSmoothScroll() {
        let supports = false;
        try {
            let div = document.createElement('div');
            div.scrollTo({
                top: 0,
                get behavior() {
                    supports = true;
                    return 'smooth';
                },
            });
        } catch (err) {
            // Edge throws an error
        }

        return supports;
    }

    smoothScrollPolyfill(node, key, target) {
        // via
        // https://github.com/tootsuite/mastodon/blob/f59ed3a4fafab776b4eeb92f805dfe1fecc17ee3/app/javascript/mastodon/scroll.js
        const easingOutQuint = (x, t, b, c, d) => c * ((t = t / d - 1) * t * t * t * t + 1) + b;
        const startTime      = Date.now();
        const offset         = node[key];
        const gap            = target - offset;
        const duration       = 1000;
        let interrupt        = false;

        const step = () => {
            const elapsed    = Date.now() - startTime;
            const percentage = elapsed / duration;

            if (interrupt) {
                return;
            }

            if (percentage > 1) {
                cleanup();
                return;
            }

            node[key] = easingOutQuint(0, elapsed, offset, gap, duration);
            requestAnimationFrame(step);
        };

        const cancel = () => {
            interrupt = true;
            cleanup(cancel);
        };

        const cleanup = () => {
            node.removeEventListener('wheel', cancel);
            node.removeEventListener('touchstart', cancel);
        };

        node.addEventListener('wheel', cancel, {passive: true});
        node.addEventListener('touchstart', cancel, {passive: true});

        step();

        return cancel;
    }
}

class Timeout {
    constructor() {
        this.isSet = false;
        this.id    = null;
    }

    // https://dev.to/monaye/refactor-davidwalsh-s-debounce-function-5afc
    static debounce(func, delay, immediate) {
        let timer;
        return (...args) => {
            const boundFunc = func.bind(this, ...args);
            clearTimeout(timer);
            if (immediate && !timer) {
                boundFunc();
            }
            const calleeFunc = immediate ? () => { timer = null; } : boundFunc;
            timer            = setTimeout(calleeFunc, delay);
        };
    }

    static throttle(func, delay, immediate) {
        let timer;
        return (...args) => {
            const boundFunc = func.bind(this, ...args);
            if (timer) {
                return;
            }
            if (immediate && !timer) {
                boundFunc();
            }
            timer = setTimeout(() => {
                if (!immediate) {
                    boundFunc();
                }
                timer = null;
            }, delay);
        };
    }

    // https://davidwalsh.name/javascript-debounce-function

    set(fn, interval, scope, args) {
        scope = scope || window;

        const self = this;
        const wrap = function() {
            self.clear();
            fn.apply(scope, args || arguments);
        };

        this.id    = setTimeout(wrap, interval);
        this.isSet = true;
    }

    clear() {
        clearTimeout(this.id);
        this.isSet = false;
        this.id    = null;
    }
}

class Carousel {
    constructor(hoax, $html) {
        this.$                = hoax;
        this.$html            = $html;
        this.currentIndex     = 0;
        this.scroll           = new Scroll();
        this.timer            = new Timeout();
        this.defaultTimerTime = 5000;

        this.pauseCookieName = 'hiper-carousel-paused';

        if (document.querySelectorAll('.carousel').length !== 1) {
            return;
        }

        this.scroller      = document.querySelector('.carousel-scroll');
        this.scrollerItems = this.scroller.querySelectorAll('.carousel-item-outer');

        if (this.scrollerItems.length === 1) {
            return;
        }

        this.controls         = document.querySelector('.carousel-controls');
        this.indicatorButtons = this.controls.querySelectorAll('.carousel-indicator');
        this.pauseButton      = this.controls.querySelector('.carousel-pause');

        this.setCurrent(this.getLiveCurrentIndex()); // Browsers can save state
        this.setPaused(this.getCookie());

        if (Media.prefersReducedMotion()) {
            this.setPaused(true);
        }

        this.initCarousel();
        this.initPausability();
        this.initAutoRotation();
    }

    getCookie() {
        return this.$.cookieGet(this.pauseCookieName, false) === 'true';
    }

    initPausability() {
        this.pauseButton.addEventListener('click', () => {
            if (this.isPaused) {
                this.startTimer();
                this.setPaused(false);
            } else {
                this.timer.clear();
                this.setPaused(true);
            }
        });
    }

    setPaused(isPaused) {
        this.isPaused = isPaused;
        this.$.cookieSet(this.pauseCookieName, isPaused, 30);
        this.pauseButton.setAttribute('aria-pressed', isPaused);
    }

    findNetIndex() {
        const count = this.scrollerItems.length;

        if (this.currentIndex === (count - 1)) {
            return 0;
        }

        return this.currentIndex + 1;
    }

    moveToNext() {
        let nextIndex = this.findNetIndex();
        this.indicatorButtons[nextIndex].click();
    }

    startTimer() {
        const that = this;
        if (this.timer.isSet || this.isPaused) {
            return;
        }
        this.timer.set(() => {
            this.moveToNext();
            that.startTimer();
        }, this.defaultTimerTime);
    }

    initAutoRotation() {
        this.controls.addEventListener('mouseover', () => {
            this.timer.clear();
        });
        this.scroller.addEventListener('mouseover', () => {
            this.timer.clear();
        });

        this.controls.addEventListener('mouseout', () => {
            this.startTimer();
        });
        this.scroller.addEventListener('mouseout', () => {
            this.startTimer();
        });

        this.startTimer();
    }

    setCurrent(index) {
        this.currentIndex = index;
        this.indicatorButtons.forEach((indicator, i) => {
            indicator.setAttribute('aria-pressed', !!(i === index));
        });
    }

    initCarousel() {
        const scroller = this.scroller;
        const count    = this.scrollerItems.length;

        this.indicatorButtons.forEach((indicator, index) => {
            indicator.addEventListener('click', e => {
                e.preventDefault();
                e.stopPropagation();
                const scrollLeft = Math.floor(scroller.scrollWidth * (index / count));
                this.scroll.smoothScrollHorizontal(scroller, scrollLeft);
                this.setCurrent(index);
            });
        });

        scroller.addEventListener('scroll', Timeout.debounce(() => {
            this.setCurrent(this.getLiveCurrentIndex());
        }, 150));

        window.addEventListener('resize', Timeout.debounce(() => {
            this.indicatorButtons[this.getLiveCurrentIndex()].click();
        }, 100));
    }

    getLiveCurrentIndex() {
        const scroller = this.scroller;
        const count    = this.scrollerItems.length;
        return Math.round((scroller.scrollLeft / scroller.scrollWidth) * count);
    }
}

((doc, win, loc, $) => {
    const addEvent       = 'addEventListener';
    const parent         = 'parentNode';
    const addClass       = 'addClass';
    const removeClass    = 'removeClass';
    const hasClass       = 'hasClass';
    const preventDefault = 'preventDefault';

    const dawa = win.dawa;

    const key_codes = {
        TAB:         9,
        ENTER:       13,
        SHIFT:       16,
        CTRL:        17,
        ALT:         18,
        CAPSLOCK:    20,
        ESCAPE:      27,
        SPACE:       32,
        PAGE_UP:     33,
        PAGE_DOWN:   34,
        END:         35,
        HOME:        36,
        LEFT_ARROW:  37,
        UP_ARROW:    38,
        RIGHT_ARROW: 39,
        DOWN_ARROW:  40,
        INSERT:      45,
    };

    let submitted_forms = [];

    const validateFormElement = (e, $form_element, exclude_types, callback) => {
        if (!$form_element) {
            return;
        }
        if (!exclude_types) {
            exclude_types = ['submit'];
        }
        if (!callback) {
            callback = () => {};
        }

        let elements    = [];
        let any_empties = false;
        $form_element.toArray().forEach(function(el) {
            if (exclude_types.indexOf(el.type) === -1 && el.required) {
                elements.push(el);

                if (el.type === 'radio') {
                    if ($form_element[el.name].value.length === 0) {
                        $[addClass](el, 'error');
                        any_empties = true;
                    }
                } else if (el.type === 'checkbox') {
                    if ($form_element[el.name].checked === false) {
                        $[addClass](el, 'error');
                        any_empties = true;
                    }
                } else if (el.value === '') {
                    $[addClass](el, 'error');
                    el[addEvent]('keyup', event_keyup => {
                        if (event_keyup.target.value.length > 0) {
                            $[removeClass](event_keyup.target, 'error');
                        }

                    });
                    any_empties = true;
                } else {
                    $[removeClass](el, 'error');
                }
            }
        });
        if (any_empties === true) {
            e[preventDefault]();
        } else {
            if (submitted_forms.indexOf($form_element) !== -1) {
                e[preventDefault]();
                return;
            }
            submitted_forms.push($form_element);
        }

        callback(e, elements, any_empties);
    };

    /**
     * This validates $form_element for empty fields, excluding exclude_types fields
     */
    const validateForm = ($form_element, exclude_types, callback) => {
        if (!$form_element) {
            return;
        }

        $form_element[addEvent](
            'submit',
            e => validateFormElement(e, $form_element, exclude_types, callback),
        );
    };

    /**
     * Send form data to GTM.
     */
    const gtmFormEvent = ($form_element, fields, status) => {
        let purpose_key = undefined;
        let purpose_value = undefined;

        if (fields['category'] !== undefined) {
            purpose_key         = fields['category'];
            const category_name = $form_element.querySelectorAll('label[for="category-' + fields['category'] + '"]');
            if (category_name.length !== 0) {
                purpose_value = category_name[0].innerText.toLowerCase();
            }
        }

        let push_data = {
            'event':   'dynamic_form_submit',
            'form_id': $form_element.id,
            'status':  status,
        };

        if (fields['call-me-type'] !== undefined) {
            push_data['call_me_type'] = fields['call-me-type'];
        }

        if (purpose_key !== undefined) {
            push_data['purpose_key'] = purpose_key;
        }

        if (purpose_value !== undefined) {
            push_data['purpose_value'] = purpose_value;
        }

        if (fields['hem'] !== undefined) {
            push_data['hem'] = fields['hem'];
        }

        if (fields['hph1'] !== undefined) {
            push_data['hph1'] = fields['hph1'];
        }

        if (fields['hph2'] !== undefined) {
            push_data['hph2'] = fields['hph2'];
        }

        win.dataLayer = win.dataLayer || [];
        win.dataLayer.push(push_data);
    };

    /**
     * This sends a form with XHR
     *
     * Expects:
     *  - data-dynamic-url on the $form_element
     *  - the url provided responds with simple JSON containing success: boolean
     *
     * Applies following classes on $form_element:
     *  - form--sending while sending
     *  - form--sent on success
     *  - form--not-sent on failure
     */
    const dynamicFormSubmit = ($form_element, process_to_gtm, on_success, on_fail) => {
        if (!$form_element) {
            return;
        }
        $form_element[addEvent]('submit', e => {
            let any_empties = false;
            let fields      = {};
            let elements    = $form_element.toArray();

            elements.forEach(element => {
                if ($[hasClass](element, 'error')) {
                    any_empties = true;
                }
                if (element.type === 'submit') {
                    return;
                }

                if (element.type === 'tel') {
                    // Phone number can optionally have +45 or 0045, and optionally a space between those and the actual phone number, but MUST contain 8 digits.
                    const phoneRegex = /^(?:\+45|0045)?\s?\d{8}$/;

                    $[removeClass](element, 'error');

                    if (!phoneRegex.test(element.value.trim())) {
                        $[addClass](element, 'error');
                        any_empties = true;
                    }
                }

                if (element.type === 'radio') {
                    if (typeof fields[element.name] !== 'undefined') {
                        return;
                    }
                    elements.some(e2 => {
                        if (e2.name === element.name && e2.checked) {
                            fields[e2.name] = e2.value;
                            return true;
                        }
                    });
                } else {
                    fields[element.name] = element.value;
                }
            });

            if (any_empties === false) {
                const url = $form_element.getAttribute('data-dynamic-url');

                $[addClass]($form_element, 'form--sending');
                $.xhr
                    .post(url, $.parseParams(fields))
                    .success(data => {

                        if (data['hem'] !== undefined) {
                            fields['hem'] = data['hem'];
                        }
                        if (data['hph1'] !== undefined) {
                            fields['hph1'] = data['hph1'];
                        }
                        if (data['hph2'] !== undefined) {
                            fields['hph2'] = data['hph2'];
                        }

                        if (data.success) {
                            if (process_to_gtm) {
                                gtmFormEvent($form_element, fields, 'ok');
                            }
                            $[addClass]($form_element, 'form--sent');
                            if (typeof on_success !== 'undefined') {
                                on_success(data);
                            }
                        } else {
                            $[addClass]($form_element, 'form--not-sent');
                            if (process_to_gtm) {
                                gtmFormEvent($form_element, fields, 'error');
                            }
                            if (typeof on_fail !== 'undefined') {
                                on_fail(data);
                            }
                        }
                    })
                    .error(data => {
                        $[addClass]($form_element, 'form--not-sent');
                        if (process_to_gtm) {
                            gtmFormEvent($form_element, fields, 'not-sent');
                        }
                    });
            } else {
                if (process_to_gtm) {
                    gtmFormEvent($form_element, fields, 'not-sent');
                }
            }
            e[preventDefault]();
        });
    };

    // eslint-disable-next-line complexity, max-statements
    $.documentReady(() => {
        const $html              = doc.documentElement;
        const $address_widget    = $('#address-widget');
        const $zip_code          = $('#zip');
        const $city              = $('#city');
        const $sbbu_yes          = $('#sbbu-yes');
        const $sbbu_no           = $('#sbbu-no');
        const $sbbu_provider     = $('#sbbu-provider');
        const $installation_date = $('#installation-date');
        const $cookie_warning    = $('#cookie-warning');
        const carousel           = new Carousel($, $html);

        win.carousel = carousel;

        win.HIPER = {};

        if ($.ie) {
            $[addClass]($html, 'ie-v' + $.ie);
        }
        const $trustpilot = $('#trustpilot--placeholder');

        if ($trustpilot) {
            $.xhr
                .get($trustpilot.getAttribute('data-trustpilot-url'))
                .success(data => $trustpilot.innerHTML = data);
        }

        // region: Load full fonts
        if ('fonts' in doc) {
            const cervo_300 = new FontFace(
                'Cervo',
                'url(/fonts/cervo-light-full.woff2) format(\'woff2\'), url(/fonts/cervo-light-full.woff) format(\'woff\')',
                {weight: '300'},
            );

            const cervo_400 = new FontFace(
                'Cervo',
                'url(/fonts/cervo-regular-full.woff2) format(\'woff2\'), url(/fonts/cervo-regular-full.woff) format(\'woff\')',
                {weight: '400'},
            );

            const cervo_300_italic = new FontFace(
                'Cervo',
                'url(/fonts/cervo-light-italic-full.woff2) format(\'woff2\'), url(/fonts/cervo-light-italic-full.woff) format(\'woff\')',
                {weight: '300', style: 'italic'},
            );

            const cervo_400_italic = new FontFace(
                'Cervo',
                'url(/fonts/cervo-regular-italic-full.woff2) format(\'woff2\'), url(/fonts/cervo-regular-italic-full.woff) format(\'woff\')',
                {weight: '400', style: 'italic'},
            );

            const montserrat_variable = new FontFace(
                'Montserrat',
                'url(/fonts/montserrat-variable-full.woff2) format(\'woff2\'), url(/fonts/montserrat-variable-full.woff) format(\'woff\')',
                {weight: '100 900'},
            );

            Promise.all([
                cervo_300.load(),
                cervo_400.load(),
                cervo_300_italic.load(),
                cervo_400_italic.load(),
                montserrat_variable.load(),
            ]).then(fonts => fonts.forEach(font => doc.fonts.add(font)));
        }
        // endregion: Load full fonts

        // region: Netinfo request
        const $dsl_product = $('#dsl-product');
        if ($dsl_product) {
            $dsl_product.forEach(dsl_product => {
                const $dsl_speed   = $('#product-list-dsl-speed');
                const url          = dsl_product.getAttribute('data-url');
                const orderId      = dsl_product.getAttribute('data-order-id');
                const offlineSpeed = dsl_product.getAttribute('data-offline-speed');
                const fields       = {
                    'order_id': orderId,
                };

                let offlineSet = false;

                $[addClass](dsl_product, 'netinfo--sending');

                $.activity_counter++;

                let netinfoTimeout = setTimeout(() => {
                    $[addClass](dsl_product, 'netinfo--not-received');
                    dsl_product.innerHTML = offlineSpeed;
                    offlineSet             = true;
                    $.activity_counter--;
                }, 60000);

                $.xhr
                    .post(url, $.parseParams(fields))
                    .success(data => {
                        if (offlineSet) {
                            return;
                        }
                        if (data.success) {
                            clearTimeout(netinfoTimeout);
                            $.activity_counter--;

                            let down  = Math.round(data.down);
                            let up    = Math.round(data.up);
                            let inner = '';

                            if (down < 1) {
                                down = '0,5';
                            }
                            if (up < 1) {
                                up = '0,5';
                            }
                            win.HIPER.netinfo = {
                                'up':   up,
                                'down': down,
                            };
                            $[addClass](dsl_product, 'netinfo--received');
                            inner                  = '<span class="speed">' + down + '<span class="thin-slash">/</span>' + up + '</span><span class="mbit">Mbit</span>';
                            dsl_product.innerHTML = inner;
                        } else {
                            clearTimeout(netinfoTimeout);
                            $.activity_counter--;

                            $[addClass](dsl_product, 'netinfo--not-received');
                            dsl_product.innerHTML = offlineSpeed;
                        }
                    })
                    .error(() => {
                        if (offlineSet) {
                            return;
                        }

                        clearTimeout(netinfoTimeout);
                        $.activity_counter--;

                        $[addClass](dsl_product, 'netinfo--not-received');
                        $dsl_speed.innerHTML = offlineSpeed;
                    });
            });
        }
        // endregion: Netinfo request

        /* region: Selfcare */
        const $selfcare_wrap = $('.selfcare-wrapper');

        $.live('.selfcare-account-form-close', 'click', event_click => {
            const editable  = $.closest(event_click.target, '.selfcare-account-editable');
            const form      = $('.selfcare-account-form', editable);
            const edit_link = $('.selfcare-account-edit-link', editable);

            $[removeClass](form, 'visible');
            $[removeClass](edit_link, 'hidden');

            event_click[preventDefault]();
        }, $selfcare_wrap);

        $.live('.selfcare-alert-superior-products__choose', 'click', function(event_click) {
            // eslint-disable-next-line no-invalid-this
            const context = this;
            event_click[preventDefault]();

            if (context.getAttribute('data-submitted')) {
                return false;
            }
            const $form = $('.selfcare-alert-superior-products__choose-form');

            $form.target_product_slug.value = context.getAttribute('data-target-product-slug');

            if (win.confirm('Er du sikker på at du vil bestille opgradering?')) {
                $form.submit();
                context.setAttribute('data-submitted', true);
                return true;
            }

            return false;
        }, $selfcare_wrap);

        if ($selfcare_wrap) {
            let selfcarePayOverdueBeaconTimer = null;

            const selfcarePayOverdueBeacon = () => {
                const $selfcare_pay_overdue = $('.selfcare-pay-overdue');
                if ($selfcare_pay_overdue) {
                    const internalCall = () => {
                        $.xhr
                            .get($selfcare_pay_overdue.getAttribute('data-dynamic-url'))
                            .success(data => {
                                if (data.active) {
                                    selfcarePayOverdueBeaconTimer = window.setTimeout(() => internalCall(), 5000);
                                } else {
                                    if (data.is_overdue) {
                                        // The job is finished, but there are still
                                        // overdue invoices - something is foobar.
                                        window.clearTimeout(selfcarePayOverdueBeaconTimer);
                                        $[addClass]($selfcare_pay_overdue, 'form--not-sent');
                                    } else {
                                        // The job is finished and there's no
                                        // overdue invoices - all is shiny.
                                        window.clearTimeout(selfcarePayOverdueBeaconTimer);
                                        $[addClass]($selfcare_pay_overdue, 'form--sent');
                                    }
                                }
                            })
                            .error(() => $[addClass]($selfcare_pay_overdue, 'form--not-sent'));

                    };
                    internalCall();
                }
            };

            selfcarePayOverdueBeacon();
        }
        /* endregion: Selfcare */

        // region: Address search

        if ($address_widget) {
            const $address_field        = $('#address-field');
            const $address_propositions = $('#address-propositions');
            const $address_list         = $('#address-list');
            const $address_not_found    = $('#address-not-found');
            const $address_wrap         = $('#address-wrap');
            const $search_error         = $('#search-error');
            const $continue_button      = $('#continue-button');
            const no_submit             = $address_widget.getAttribute('data-no-submit') === 'true';
            const default_address       = $address_widget.getAttribute('data-default-value');
            const default_address_data  = $address_widget.getAttribute('data-default-dawa-data-value');

            const _commonNavigate = data => {
                if (!data || !$address_field.value) {
                    return;
                }
                if (!no_submit) {
                    $('#send-dawa-address').submit();
                }
            };

            const navigateFromList = data => {
                $[removeClass]($address_widget, 'typing');
                $[removeClass]($html, 'address-lookup-typing');
                $[removeClass]($address_propositions, 'visible');
                $[addClass]($html, 'address-lookup-typing-found');
                $[removeClass]($continue_button, 'state-search');
                $[addClass]($continue_button, 'state-go');

                if ($address_field.getAttribute('data-address-found') !== '') {
                    _commonNavigate(data);
                }

                $('#dawa_data').value = JSON.stringify(data);
                $address_field.setAttribute('data-address-found', $address_field.value);
            };

            dawa.init({
                addressField:     $address_field,
                addressList:      $address_list,
                navigateFromList: navigateFromList,
            });

            $address_field[addEvent]('focus', event_focus => {
                if (event_focus.target.value === default_address) {
                    event_focus.target.value = '';
                    event_focus.target.setAttribute('data-address-found', '');
                    $('#dawa_data').value = '';
                }
            });

            $address_field[addEvent]('blur', event_blur => {
                if (event_blur.target.value === '' && default_address) {
                    event_blur.target.value = default_address;
                    event_blur.target.setAttribute('data-address-found', default_address);
                    $('#dawa_data').value = default_address_data;
                }
            });

            $address_field[addEvent]('input', Timeout.debounce(event_keyup => {
                const keyCode     = (win.event) ? event_keyup.which : event_keyup.keyCode;
                const ignoreCodes = Object.keys(key_codes).map(key => key_codes[key]);
                const target      = event_keyup.target;

                if (target.value.length > 1 && target.getAttribute('data-address-found') !== target.value) {
                    target.setAttribute('data-address-found', '');
                    $('#dawa_data').value = '';

                    $[addClass]($address_widget, 'typing');
                    $[addClass]($html, 'address-lookup-typing');
                    $[addClass]($address_propositions, 'visible');

                    $[addClass]($continue_button, 'state-search');
                    $[removeClass]($continue_button, 'state-go');

                    if (ignoreCodes.indexOf(keyCode) === -1) {
                        dawa.search(target.value, false, target, $address_list, $address_wrap);
                    }
                } else {
                    $[removeClass]($address_widget, 'typing');
                    $[removeClass]($html, 'address-lookup-typing');
                    $[removeClass]($address_propositions, 'visible');
                }
                if (target.value.length < 1) {
                    $[removeClass]($html, 'address-lookup-typing-found');
                }
            }, 250));

            $address_field[addEvent]('keydown', event_keydown => {
                const key = event_keydown.keyCode || event_keydown.which;
                if (event_keydown.target.value.length > 1) {
                    if (key === key_codes.UP_ARROW) {
                        dawa.navigateUp($address_list);
                        event_keydown[preventDefault]();
                    }
                    if (key === key_codes.DOWN_ARROW) {
                        dawa.navigateDown($address_list);
                        event_keydown[preventDefault]();
                    }
                    if (key === key_codes.ENTER) {
                        dawa.navigateSelect(navigateFromList);
                        event_keydown[preventDefault]();
                    }
                }
            });

            $continue_button[addEvent]('click', e => {
                dawa.navigateSelect(navigateFromList);
                e[preventDefault]();
            });

            if ($address_not_found) {
                $address_not_found[addEvent]('click', e => {
                    $[addClass]($html, 'show-manual-address');
                    $[addClass]($html, 'hide-dawa-address');
                    $[addClass]($html, 'address-lookup-typing');
                    $[removeClass]($address_widget, 'typing');
                    $('#dawa_data').value = '';

                    if ($address_field.value.length > 0) {
                        $('#street').value = $address_field.value.replace(/[\d,.:/\\;'"]|\s[\d,.:/\\;'"]/ig, '');
                    }

                    e[preventDefault]();
                });
            }

            if ($search_error) {
                $search_error[addEvent]('click', e => {
                    $[addClass]($html, 'show-manual-address');
                    $[addClass]($html, 'hide-dawa-address');
                    $[addClass]($html, 'address-lookup-typing');
                    $[removeClass]($address_widget, 'typing');

                    e[preventDefault]();
                });
            }
        }

        // sticky address
        const $sticky = $('.address-widget-floating');
        if ($sticky) {
            window.addEventListener('scroll', function() {
                if (!$address_widget || !$sticky) {
                    return;
                }

                const $sticky_position = $sticky.getBoundingClientRect().top + window.scrollY - $('.site-header').offsetHeight;

                $address_widget.classList.toggle('sticky', window.scrollY >= $sticky_position);
            });
        }

        // address focus
        const $address_focus = $('.address-button')
        const $address_overlay = $('#address-overlay');
        if ($address_focus && $address_overlay) {
            const $address_field = $('#address-field');
            const $manual_field = $('#street');

            $address_focus.toArray().forEach((button) => {
                button.addEventListener('click', (event) => {
                    event.preventDefault();

                    // Enable overlay
                    $address_overlay.classList.add('active');

                    // Check if dawa is enabled
                    if ($html.classList.contains('show-manual-address')) {
                        // Focus street input
                        $manual_field.focus();
                    } else {
                        // Focus address bar
                        $address_field.focus();
                        $address_widget.classList.add('focus');
                    }
                })
            })

            $address_overlay.onclick = function() {
                $address_widget.classList.remove('focus');

                $address_overlay.classList.remove('active');
            };
        }
        // endregion: Address search

        // region: Zipcode
        if ($zip_code && $city) {
            $zip_code[addEvent]('keyup', event_keyup => {
                const target = event_keyup.target;

                if (target.value.length > 4) {
                    target.value = target.value.substr(0, 4);
                }
                if (target.value.length === 4) {
                    //todo: WTF city?
                    dawa.findCityByZip(target.value, win.city);
                }
            });
        }
        // endregion: Zipcode

        // region: SBBU functionality
        if ($sbbu_yes && $sbbu_no && $sbbu_provider) {
            $sbbu_yes[addEvent]('change', event_change => {
                if (event_change.target.checked) {
                    $[addClass]($('#sbbu-details'), 'visible');
                    $sbbu_provider.setAttribute('required', '');
                    $('#sbbu_reference').setAttribute('required', '');
                }
            });
            $sbbu_no[addEvent]('change', event_change => {
                if (event_change.target.checked) {
                    $[removeClass]($('#sbbu-details'), 'visible');
                    $sbbu_provider.removeAttribute('required');
                    $('#sbbu_reference').removeAttribute('required');
                    $('#sbbu-custom-provider input').removeAttribute('required');
                }
            });
        }
        if ($sbbu_provider) {
            $sbbu_provider[addEvent]('change', () => {
                const chosen_sbbu = $('#sbbu-provider').item($('#sbbu-provider').selectedIndex);
                const chosen_sbbu_text = chosen_sbbu.attributes['data-helptext'].value;

                if (chosen_sbbu.attributes['data-requires-custom'].value) {
                    $[removeClass]($('#sbbu-custom-provider'), 'sbbu-custom-provider-hidden');
                    $('#sbbu-custom-provider input').setAttribute('required', '');
                } else {
                    $[addClass]($('#sbbu-custom-provider'), 'sbbu-custom-provider-hidden');
                    $('#sbbu-custom-provider input').removeAttribute('required');
                }
                $('#sbbu-hint').innerHTML = chosen_sbbu_text;
            });
        }
        // endregion: SBBU functionality

        // region: Installation datepicker
        if ($installation_date) {
            const $installation_date_no      = $('#specify-installation-date-no');
            const $installation_date_yes     = $('#specify-installation-date-yes');
            const $installation_date_details = $('.installation-date');

            win.flatpickr('#installation-date', {
                dateFormat:            'Y-m-d',
                altFormat:             null,
                altInput:              null,
                maxDate:               null,
                disable:               null,
                shorthandCurrentMonth: false,
                inline:                true,
                prevArrow:             '&#9664;',
                nextArrow:             '&#9654;',
            });

            $installation_date_no[addEvent]('change', event_change => {
                if (event_change.target.checked) {
                    $[removeClass]($installation_date_details, 'visible');
                }
            });

            $installation_date_yes[addEvent]('change', event_change => {
                if (event_change.target.checked) {
                    $[addClass]($installation_date_details, 'visible');
                }
            });
        }
        // endregion: Installation datepicker

        const $order_type = $('.contact__order-type');
        if ($order_type) {
            const ORDER_TYPE_PRIVATE   = $('#order-type-private').value;
            const ORDER_TYPE_BUSINESS  = $('#order-type-business').value;
            const $invoice             = $('.contact__invoice');
            const radios               = $('input[type=radio]', $order_type);
            const $invoice_email       = $('#invoice_email');
            const $invoice_first_name  = $('#invoice_first_name');
            const $invoice_last_name   = $('#invoice_last_name');
            const $contact_first_name  = $('#first_name');
            const $contact_last_name   = $('#last_name');
            const $contact_email       = $('#contact__email');
            const $invoice_phone       = $('#invoice_phone_no');
            const $contact_mobile      = $('#contact__mobile_no');
            const default_address      = $('#address-widget').getAttribute('data-default-value');
            const default_address_data = $('#address-widget')
                .getAttribute('data-default-dawa-data-value');
            const $invoice_address     = $('#address-field');

            const radioCallback = event_click => {
                if (event_click.target.value === ORDER_TYPE_PRIVATE) {
                    $[removeClass]($invoice, 'visible');
                    $('input', $invoice).toArray().forEach(element => element.tabIndex = '-1');

                    $invoice[addEvent]('transitionend', function transition_callback(event) {
                        if ($('input[name=order-type]:checked').value === ORDER_TYPE_PRIVATE) {
                            let inputs = $('input', $invoice);
                            for (let i = 0; i < inputs.length; i++) {
                                inputs[i].value = '';
                            }

                            $[removeClass]($html, 'address-lookup-*');
                            $[removeClass]($html, 'hide-*-address');
                            $[removeClass]($html, 'show-*-address');
                            $[removeClass]($address_widget, 'typing');
                            $[removeClass]($('#address-propositions'), 'visible');
                            $[removeClass]($('#continue-button'), 'state-go');
                            $[addClass]($('#continue-button'), 'state-search');

                            $invoice_address.setAttribute('data-address-found', '');
                            $('#dawa_data').value = '';

                        }
                        event.target.removeEventListener(event.type, transition_callback);
                    });
                }
                if (event_click.target.value === ORDER_TYPE_BUSINESS) {
                    $[addClass]($invoice, 'visible');
                    $('input', $invoice).toArray().forEach(element => element.tabIndex = '');

                    $invoice[addEvent]('transitionend', function transition_callback(event) {
                        if ($('input[name=order-type]:checked').value === ORDER_TYPE_BUSINESS) {
                            $invoice_first_name.value = $contact_first_name.value;
                            $invoice_last_name.value  = $contact_last_name.value;
                            $invoice_email.value      = $contact_email.value;
                            $invoice_phone.value      = $contact_mobile.value;
                            $invoice_address.value    = default_address;
                            $invoice_address.setAttribute('data-address-found', default_address);
                            $('#dawa_data').value = default_address_data;
                        }
                        event.target.removeEventListener(event.type, transition_callback);
                    });
                }
            };

            for (let i = 0; i < radios.length; i++) {
                radios[i][addEvent]('click', radioCallback);
            }
        }

        // region: SBBU datepicker
        if ($('#sbbu-date')) {
            win.flatpickr('#sbbu-date', {
                dateFormat:            'Y-m-d',
                altFormat:             null,
                altInput:              null,
                //maxDate:               null,
                disable:               null,
                shorthandCurrentMonth: false,
                inline:                true,
                prevArrow:             '&#9664;',
                nextArrow:             '&#9654;',
            });
        }
        // endregion: SBBU datepicker

        // region: Form Validation
        const registerContactInfo       = $('#register-contact-info')
        const registerCampaignCode      = $('#register-campaign-code')
        const warning                   = $('#continue-campaign-code-warning')

        validateForm(registerContactInfo, ['checkbox', 'submit']);
        validateForm($('.sbbu-form-dsl'), ['checkbox', 'submit']);
        validateForm($('.sbbu-form'),  ['submit']);
        validateForm($('#register-installation'));
        validateForm($('#register-products-not-found'));

        // check that we have no contact info already before we submit the campaign code.
        // else we throw out the contact info when we submit this, if they wrote anything
        validateForm(registerCampaignCode, ['submit'], (e, elements, any_empties) => {
            // no need to validate if there is some contact info if the campaign code is empty
            if (any_empties) {
                return false
            }

            if (registerContactInfo === null) {
                return false
            }

            registerContactInfo.toArray().forEach(function(el) {
                // only check tel, email and text fields
                if (el.required && !['radio', 'checkbox'].includes(el.type)) {
                    if (el.value === '') {
                        return true
                    }

                    warning.classList.add('visible')
                    contactFormContinueButton.classList.add('shimmer')

                    e.preventDefault()
                    return false
                }
            })
        });

        /**
         * Manual address validation
         */
        const $manual_address = $('#send-manual-address');
        if ($manual_address) {
            $manual_address[addEvent]('submit', event_submit => {
                let any_errors = false;
                [$('#street'), $('#house'), $('#zip')].forEach(element => {
                    if (element.value === '') {
                        $[addClass](element, 'error');
                        element[addEvent]('keyup', event_keyup => {
                            if (event_keyup.target.value.length > 0) {
                                $[removeClass](event_keyup.target, 'error');
                            }
                        });
                        any_errors = true;
                    } else {
                        $[removeClass](element, 'error');
                    }
                });
                if (any_errors === true) {
                    event_submit[preventDefault]();
                } else {
                    if (submitted_forms.indexOf($manual_address) !== -1) {
                        event_submit[preventDefault]();
                        return;
                    }
                    submitted_forms.push($manual_address);
                }
            });
        }

        /**
         * Order Summary checkbox validation
         */
        const $order_complete = $('#order-complete');
        if ($order_complete) {
            $order_complete[addEvent]('submit', e => {
                if ($('#accept-conditions').checked === false) {
                    $[addClass]($('#accept-conditions'), 'error');
                    e[preventDefault]();
                } else {
                    $[removeClass]($('#accept-conditions'), 'error');

                    if (submitted_forms.indexOf($order_complete) !== -1) {
                        e[preventDefault]();
                        return;
                    }
                    submitted_forms.push($order_complete);
                }
            });
        }

        const $mailcheck_fields = $('.mailcheck');
        if ($mailcheck_fields) {
            $mailcheck_fields.toArray().forEach(field => {
                const $suggestion       = $(field.getAttribute('data-mailcheck-suggestion'));
                const $suggestion_link  = $(field.getAttribute('data-mailcheck-suggestion-link'));
                const $suggestion_email = $(field.getAttribute('data-mailcheck-suggestion-email'));

                field[addEvent]('blur', () => {
                    win.Mailcheck.run({
                        email: field.value,

                        suggested: suggestion => {
                            $suggestion_email.innerHTML = suggestion.full;
                            $suggestion_link.setAttribute(
                                'data-mailcheck-suggestion-email-value',
                                suggestion.full,
                            );
                            $[addClass]($suggestion, 'visible');
                        },

                        empty: () => $[removeClass]($suggestion, 'visible'),
                    });
                });
                $suggestion_link[addEvent]('click', e => {
                    field.value = $suggestion_link.getAttribute('data-mailcheck-suggestion-email-value');
                    field.focus();
                    $[removeClass]($suggestion, 'visible');
                    e[preventDefault]();
                });
            });
        }

        // endregion: Form Validation

        // region: Dynamic Forms

        dynamicFormSubmit($('#call-me-inline-form'), true);
        dynamicFormSubmit($('#call-me-floating-form'), true);

        // endregion: Dynamic Forms

        // region: Overlay Functionality
        const $overlays = $('.wl-overlay');
        if ($overlays) {
            $overlays.toArray().forEach(wl_overlay => wl_overlay[addEvent]('click', overlay => {
                if (overlay.target === wl_overlay) {
                    $[removeClass](overlay.target, 'visible');
                }
            }));
        }

        const $close_links = $('.wl-overlay__close-link');
        if ($close_links) {
            $('.wl-overlay__close-link')
                .toArray()
                .forEach(close_link => close_link[addEvent]('click', event_click => {
                    const target = event_click.target;
                    if (target[parent].className === 'products-show-call-me') {
                        $[addClass](target[parent], 'hidden');
                        event_click[preventDefault]();
                        $.cookieSet('hiper-hide-products-show-call-me', '1', 7);
                        return;
                    }
                    $[removeClass](target[parent][parent], 'visible');
                    $('.wl-overlay').toArray().forEach(function(wl_overlay) {
                        $[removeClass](wl_overlay, 'visible');
                    });
                    event_click[preventDefault]();
                }));
        }
        // endregion: Overlay Functionality

        // region: Product Card Functionality
        const $product_cards = $('.order .product-list-link');
        if ($product_cards) {
            $product_cards.toArray().forEach(product_link => product_link[addEvent]('click', e => {
                const is_enabled = $[hasClass](product_link[parent], 'enabled');
                const ecommerce  = product_link.getAttribute('data-ecommerce-script-onclick');

                if (ecommerce) {
                    win.dataLayer = win.dataLayer || [];
                    win.dataLayer.push(JSON.parse(ecommerce));
                    return;
                }

                e[preventDefault]();
            }));
        }
        // endregion: Product Card Functionality

        // region: Cookie Warning
        if ($cookie_warning) {
            $('#accept-cookies')[addEvent]('click', e => {
                $.cookieSet('hiper-cookies-accepted', '1', 365);
                $cookie_warning.style.display = 'none';
                e[preventDefault]();
            });

            if ($.cookieGet('hiper-cookies-accepted') === '1') {
                $cookie_warning.style.display = 'none';
            }
        }
        // endregion: Cookie Warning

        const $service_status_finished_button = $('.issues-finished--button');
        if ($service_status_finished_button) {
            $service_status_finished_button[addEvent]('click', event_click => {
                $.toggleClass(event_click.target[parent], 'issues-finished--list__hidden');
                $.toggleClass(event_click.target[parent], 'issues-finished--list__visible');
                event_click[preventDefault]();
            });
        }

        const $service_status_issues_with_updates = $('.service-status-issue__has-messages');
        if ($service_status_issues_with_updates) {
            $service_status_issues_with_updates
                .toArray()
                .forEach(issue => issue[addEvent]('click', event_click => {
                    if (event_click.target.nodeName === 'A') {
                        return;
                    }
                    if ($[hasClass](issue, 'service-status-issue__unfolded')) {
                        event_click[preventDefault]();
                        return;
                    }
                    $.toggleClass(issue, 'service-status-issue__messages-visible');
                    event_click[preventDefault]();
                }));
        }

        // region: exit rmo
        const $exitRmoContainer = $('#exit-rmo-container');
        if ($exitRmoContainer) {
            const showRmoContainer = () => {
                if (!$.cookieGet('exit-rmo-hide')) {
                    $exitRmoContainer.classList.add('exit-rmo-show');
                    $exitRmoContainer.classList.remove('exit-rmo-hide');
                }

                const $callMeInlineContainer = $('#call-me-inline-container');
                if ($callMeInlineContainer) {
                    $exitRmoContainer.classList.add('call-me-inline-suppres');
                }
            };

            const hideRmoContainer = () => {
                $exitRmoContainer.classList.add('exit-rmo-hide');
                $exitRmoContainer.classList.remove('exit-rmo-show');
                $.cookieSet('exit-rmo-hide', '1', 30);

                const $callMeInlineContainer = $('#call-me-inline-container');
                if ($callMeInlineContainer) {
                    $exitRmoContainer.classList.remove('call-me-inline-suppres');
                }
            };

            // enable click to close
            const $exitRmoHideContainerBtn = $('#exit-rmo-container-hide-btn');
            if ($exitRmoHideContainerBtn) {
                $exitRmoHideContainerBtn[addEvent]('click', e => {
                    hideRmoContainer();
                });
            }

            // show after 180 seconds
            setTimeout(() => {
                showRmoContainer();
            }, 180 * 1000);

            // show when going away from page
            window.onblur = () => {
                showRmoContainer();
            };
        }
        // end region: exit rmo

        // region: chatbot illustration
        const $chatBotContainer = $('#chatbot-container');
        if ($chatBotContainer) {
            if (!$.cookieGet('chatbot-hide')) {
                $chatBotContainer.classList.add('chatbot-show');
            }

            const hideChatBotContainer = () => {
                $chatBotContainer.classList.remove('chatbot-show');
                $.cookieSet('chatbot-hide', '1', 1);
            };

            // enable click to close
            const $chatBotCloseButton = $('#chatbot-container-hide-btn');
            if ($chatBotCloseButton) {
                $chatBotCloseButton[addEvent]('click', e => {
                    hideChatBotContainer();
                });
            }

            // temporarily hide on home/index
            const $frontpage = $('.page-home');

            if ($frontpage) {
                $chatBotContainer.classList.add('chatbot-temp-hide');

                window.addEventListener('scroll', function() {
                $chatBotContainer.classList.toggle('chatbot-temp-hide', window.scrollY <= 60);
            });
            }
        }

        // end region: exit chatbot

        $[addClass]($html, 'loaded');
    });

    // region: accordion
    const $accordionButton = $('.accordion');
    if ($accordionButton) {
        $accordionButton.forEach((accordion) => {
            accordion.onclick = function() {
                let content      = this.nextElementSibling;
                const noJsHeight = content.classList.contains('no-js-height');

                this.classList.toggle('active');
                content.classList.toggle('active');

                if (content.style.maxHeight && !noJsHeight) {
                    content.style.maxHeight = null;
                } else if (!content.style.maxHeight && !noJsHeight) {
                    content.style.maxHeight = content.scrollHeight + 'px';
                }
            };

            // Recalculate content height, if window is resized and max-height is smaller than scrollHeight
            window.addEventListener('resize', () => {
                if (accordion.classList.contains('active') && !accordion.nextElementSibling.classList.contains('no-js-height')) {
                    let content = accordion.nextElementSibling;

                    if (content.style.maxHeight.replace('px','') < content.scrollHeight) {
                        content.style.maxHeight = content.scrollHeight + 'px';
                    }
                }
            });
        });
    };
    // end region: accordion

    // region: coverage map interactive
    const $coverageButtons = $('.coverage-button');
    const $coverageRegions = $('.coverage-region');

    if ($coverageButtons) {
        $coverageButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                e.preventDefault();

                // Remove last active button
                $coverageButtons.forEach((btn) => {
                    btn.className = btn.className.replace(' active', '');
                });

                const filter = e.target.dataset.filter;

                // Set opacity 1 0
                $coverageRegions.forEach((region) => {
                    if (region.classList.contains(filter)) {
                        region.classList.add('active');
                    } else {
                        region.classList.remove('active');
                    }
                });

                // Set button as active
                e.srcElement.className += ' active';
            });
        });
    }
    // end region: coverage map interactive

    // region: navigation

    // big nav
    const $landing_page = [
        'page-campaign-aarhus',
        'page-campaign-fibia-aarhus',
        'page-campaign-coop',
        'page-campaign-norlys',
        'page-campaign-tdcnet-migration',
        'page-campaign-tilbud',
        'page-products-tdc5g',
        'page-products-mobile',
    ];
    if ($landing_page.some(className => $('body').classList.contains(className))) {
        $('.site-main').style.paddingTop = 0;

        $('.site-header').classList.add('site-header--big');

        window.addEventListener('scroll', function() {
            $('.site-header').classList.toggle('site-header--big', window.scrollY == 0);
        });
    };

    // burger menu
    const $nav       = $('.site-navigation-container');
    const $burger    = $('.burger-menu');
    const $spies     = $('.spies-banner');
    const $spiesCont = $('.spies-banner-container');

    if ($spiesCont) {
        $spiesCont.style.height = $spies.offsetHeight + "px"; // Set height of container
    }

    $burger.onclick = function() {
        $nav.classList.toggle('menu-open');

        if ($spiesCont) {
            if ($nav.classList.contains('menu-open')) {
                $spiesCont.style.marginTop = "-" + $spies.offsetHeight + "px"; // Hide banner
            } else {
                $spiesCont.style.marginTop = 0; // Show banner
            }
        }

        // Stops body scrolling on menu-open
        if ($('body').style.overflowY === "hidden") {
            $('body').style.overflowY = "scroll";
        } else {
            $('body').style.overflowY = "hidden";
        }
    };

    // drop down
    const $dropdown = $('.dropdown-button');
    if ($dropdown) {
        $dropdown.toArray().forEach((dropDown) => {
            dropDown.onclick = function() {
                // If it looks stupid but works, it ain't stupid
                this.parentNode.parentNode.parentNode.classList.toggle('active');
            };
        });
    };

    // remove open burger menu and open dropdown if window is resized above 600px
    window.addEventListener('resize', () => {
        if ($spiesCont) {
            $spiesCont.style.height = $spies.offsetHeight - 1 + "px"; // Update banner height on resize

            if ($nav.classList.contains('menu-open')) { // If burger menu is open, do...
                if (window.innerWidth <= 730) { // If mobile, do...
                    if ($spiesCont.style.marginTop != 0 ) { // If margin-top is not 0, do...
                        $spiesCont.style.marginTop = "-" + $spies.offsetHeight + "px"; // Match margin-top to height on resize
                    }
                }

                if (window.innerWidth > 730) { // If not mobile, do...
                    if ($spiesCont.style.marginTop != 0) { // If margin-top not 0, do...
                        $spiesCont.style.marginTop = 0; // Show banner
                    }
                }
            }
        }

        if (window.innerWidth > 730) {
            // remove burger .menu-open
            if ($nav.classList.contains('menu-open')) {
                $nav.classList.remove('menu-open');
                // make body scrollable again
                $('body').style.overflowY = 'scroll';
            }
            // remove dropdown .active
            $('.navigation-link--dropdown').toArray().forEach((nav_dropdown) => {
                if ($[hasClass](nav_dropdown, 'active')) {
                    nav_dropdown.classList.remove('active');
                }
            });
        }
    });
    // end region: navigation

    // region: Order Change Address
    const $changeAddressButton = $('#changeAddressButton')
    if ($changeAddressButton) {
        const $addressCancel = $('#changeAddressCancel')
        const $addressOverlay = $('.changeable-overlay')
        const $addressWarning = $('.changeable-warning')

        $changeAddressButton.onclick = function(e) {
            e.preventDefault;

            $addressWarning.style.display = 'block';
            $addressOverlay.style.display = 'block';
        }

        $addressCancel.onclick = function(e) {
            e.preventDefault;

            if ($addressWarning.style.display === 'block') {
                $addressWarning.style.display = 'none';
                $addressOverlay.style.display = 'none';
            }
        }

        $addressOverlay.onclick = function() {
            if ($addressWarning.style.display === 'block') {
                $addressWarning.style.display = 'none';
                $addressOverlay.style.display = 'none';
            }
        }
    }
    // end region: Order Change Address

    // region: Order Summary Expand
    const $summaryExpandable = $('.expand-summary-table');
    if ($summaryExpandable) {
        $summaryExpandable.forEach((button) => {
            button.onclick = function() {
                this.classList.toggle('visible');
            };
        });
    }
    // end region: Order Summary Expand

    // region: countdown timers based on data properties. Example:
    // <span class="countdown-timer" data-date="October 5, 2023 23:59:59"></span>
    const diffInHumanTime = (date) => {
        const countDownDate = new Date(date).getTime();
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            return undefined
        }

        let ret = ''
        if (days > 1) {
            ret += `${days}\u00A0dage `
        } else if (days === 1) {
            ret += `${days}\u00A0dag `
        }

        if (hours > 1) {
            ret += `${hours}\u00A0timer `
        } else if (days === 1) {
            ret += `${hours}\u00A0time `
        }

        return `${ret}${minutes}\u00A0min ${seconds}\u00A0sek`;
    }

    const updateWithHumanTime = (element) => {
        const newText = diffInHumanTime(element.dataset.date)
        if (newText !== undefined) {
            element.textContent = newText
        } else {
            element.textContent = '0 dage 0 timer 0 min 0 sek'
        }
    }

    const countDownTimers = document.getElementsByClassName('countdown-timer')
    for (let i = 0; i < countDownTimers.length; i++) {
        updateWithHumanTime(countDownTimers[i])
        setInterval(function() {
            updateWithHumanTime(countDownTimers[i])
        }, 1000)
    }
    // end region: countdown timers

    // start region: Get OS
    const $heroContainer = $('#hero-container');

    if ($heroContainer) {
        function getOS() {
            const userAgent        = window.navigator.userAgent,
                  platform         = window.navigator?.userAgentData?.platform || window.navigator.platform,
                  macosPlatforms   = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                  windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                  iosPlatforms     = ['iPhone', 'iPad', 'iPod'];
            let os = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'mac';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'ios';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'windows';
            } else if (/Android/.test(userAgent)) {
                os = 'android';
            } else if (/Linux/.test(platform)) {
                os = 'linux';
            }

            return os;
        }

        $heroContainer.classList.add(getOS());
    }
    // end region: Get OS

    // start region: Hero popup
    const $specificAddressPopup = $('.specific-address-popup');

    if ($specificAddressPopup) {
        const $openPopupButton = $('.open-popup-button');
        const $closePopup      = $('.close-popup');
        const $closePopupOrder = $('.close-popup-order');

        // Function to show popup
        $openPopupButton.onclick = function() {
            $specificAddressPopup.classList.add('visible');
        };

        // Function to hide popup
        $closePopup.onclick = function() {
            $specificAddressPopup.classList.remove('visible');
        };

        $closePopupOrder.onclick = function() {
            $specificAddressPopup.classList.remove('visible');
        };

        // Hide the popup if you click outside the popup content
        $specificAddressPopup.onclick = function(e) {
            if (e.target === $specificAddressPopup) {
                $specificAddressPopup.classList.remove('visible');
            }
        };
    }
    // end region: Hero popup

})(document, window, window.location, window.hoax);
